<template>
  <section data-theme="legend" class="tw-overflow-x-hidden tw-font-poppins tw-font-normal">
    <section class="tw-flex tw-flex-col tw-min-h-screen tw-h-full">
      <slot />
      <CommonFooter />
    </section>
    <CommonBackToTop />
    <CommonSetting />
  </section>
</template>
