<template>
  <div class="tw-fixed tw-bottom-0 tw-right-0 tw-z-40 tw-m-4">
    <button v-show="model" class="tw-btn tw-btn-circle tw-mt-auto pointer-events-initial" @click="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>chevron-up</title>
        <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
      </svg>
    </button>
  </div>
</template>

<script setup type="ts">
  import { ref } from 'vue'

  const model = ref(false)

  function onScroll() {
      model.value = window.scrollY > 200
  }

  function onClick() {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      })
  }
  onMounted(() => {
      window.addEventListener('scroll', onScroll)
  })
  onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
  })
</script>

<style scoped>
  .pointer-events-none {
    pointer-events: none;
  }

  .pointer-events-initial {
    pointer-events: initial;
  }
</style>
