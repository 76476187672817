<template>
  <div class="tw-fixed tw-bottom-0 tw-left-0 tw-z-40 tw-m-4 tw-flex tw-flex-col tw-gap-y-2" v-if="enableDevTools">
    <button class="tw-btn tw-btn-circle tw-mt-auto tw-cursor-pointer" @click="onWeblateKey">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>查看 Weblate 字典</title>
        <path
          d="M4 12V9C4 11.2 7.6 13 12 13S20 11.2 20 9V12C20 12.5 19.8 12.9 19.5 13.4C18.7 13.1 17.9 13 17 13C14.5 13 12.1 14.1 10.6 15.9C6.8 15.6 4 14 4 12M12 11C16.4 11 20 9.2 20 7S16.4 3 12 3 4 4.8 4 7 7.6 11 12 11M9.1 19.7L8.8 19L9.1 18.3C9.2 18.1 9.3 18 9.3 17.8C6.2 17.2 4 15.8 4 14V17C4 18.8 6.4 20.3 9.7 20.8C9.5 20.5 9.3 20.1 9.1 19.7M17 18C16.4 18 16 18.4 16 19S16.4 20 17 20 18 19.6 18 19 17.6 18 17 18M23 19C22.1 21.3 19.7 23 17 23S11.9 21.3 11 19C11.9 16.7 14.3 15 17 15S22.1 16.7 23 19M19.5 19C19.5 17.6 18.4 16.5 17 16.5S14.5 17.6 14.5 19 15.6 21.5 17 21.5 19.5 20.4 19.5 19Z"
        />
      </svg>
    </button>
    <button class="tw-btn tw-btn-circle tw-mt-auto tw-cursor-pointer">
      <span class="tw-loading tw-loading-spinner tw-loading-md" v-if="isLoading"></span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-else @click="onRefetch()">
        <title>在线更新 weblate 数据</title>
        <path
          d="M12 18.5C12 19 12.07 19.5 12.18 20H6.5C5 20 3.69 19.5 2.61 18.43C1.54 17.38 1 16.09 1 14.58C1 13.28 1.39 12.12 2.17 11.1S4 9.43 5.25 9.15C5.67 7.62 6.5 6.38 7.75 5.43S10.42 4 12 4C13.95 4 15.6 4.68 16.96 6.04C18.32 7.4 19 9.05 19 11C20.15 11.13 21.1 11.63 21.86 12.5C22.1 12.76 22.29 13.05 22.46 13.36C21.36 12.5 20 12 18.5 12C14.91 12 12 14.91 12 18.5M18 14.5C15.79 14.5 14 16.29 14 18.5S15.79 22.5 18 22.5C19.68 22.5 21.12 21.47 21.71 20H20C19.54 20.61 18.82 21 18 21C16.62 21 15.5 19.88 15.5 18.5S16.62 16 18 16C18.69 16 19.32 16.28 19.77 16.73L18 18.5H22V14.5L20.83 15.67C20.11 14.95 19.11 14.5 18 14.5Z"
        />
      </svg>
    </button>
  </div>
</template>
<script setup lang="ts">
  const { setLocaleMessage, locale, fallbackLocale } = useI18n()
  const {
    public: { enableDevTools },
  } = useRuntimeConfig()

  const useWeblateQuery = () => {
    return useQuery({
      queryKey: ['useWeblateQuery'],
      queryFn: () => $fetch(`/api/weblate?lang=${locale.value}`),
      enabled: false,
    })
  }

  const { isLoading, data, isSuccess, refetch } = useWeblateQuery()


  const onRefetch = async () => {
    await refetch()
    if (unref(isSuccess)) {
      setLocaleMessage(locale.value, data.value as any)
    }
  }

  const onWeblateKey = () => {
    fallbackLocale.value = ''
    setLocaleMessage(locale.value, {})
  }
</script>
